import { Link } from 'gatsby';
import { Button, ContentArea, PageTitle } from '@bscs-dev-team/bscs-design-system-core';
import tlsynLogo from 'images/project-logos/tlsyn_logo.svg';
import edcLogo from 'images/project-logos/edc-logo.svg';
import kstfLogo from 'images/project-logos/logo-kstf.svg';
import hriLogo from 'images/project-logos/hri-logo.svg';
import nsfLogo from 'images/project-logos/nsf-logo.svg';
import * as React from 'react';
export default {
  Link,
  Button,
  ContentArea,
  PageTitle,
  tlsynLogo,
  edcLogo,
  kstfLogo,
  hriLogo,
  nsfLogo,
  React
};