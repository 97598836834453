import { Link } from 'gatsby';
import { Button, ContentArea, PageTitle } from '@bscs-dev-team/bscs-design-system-core';
import fiveesLogo from 'images/5e_emblem.svg';
import * as React from 'react';
export default {
  Link,
  Button,
  ContentArea,
  PageTitle,
  fiveesLogo,
  React
};