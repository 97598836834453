import React, {
  FC,
  MouseEvent,
  UIEvent,
  useState,
  useEffect
} from 'react'

import './button-group-display.css'


type Item = {
  title: string,
  buttonTitle: string | undefined,
  html: string
}

type ButtonGroupDisplayProps = {
  className?: string,
  items: Item[]
}

const scrollLeft = (elem: HTMLElement, scroll_left: number, width: number) => {
  elem.scrollLeft = scroll_left - width
  scroll_left = scroll_left - width
}

const scrollRight = (elem: HTMLElement, scroll_left: number, width: number) => {
  elem.scrollLeft = scroll_left + width
  scroll_left = scroll_left + width
}

const handleClick = (e: MouseEvent, index: number, active: number): NodeJS.Timeout | undefined => {
  e.preventDefault()

  const elem = (document.getElementsByClassName('bgd-display')[0] as HTMLElement)
  const width: number = elem.offsetWidth

  let scroll_left = elem.scrollLeft
  const scroll_target = index * width

  let scroll_interval: NodeJS.Timeout | undefined

  if (index !== active) {
    const times: number = Math.abs(active - index)
    let count = 0

    scroll_interval = setInterval(() => {
      ++count === times + 1
        ? scroll_interval && clearInterval(scroll_interval)
        : scroll_left < scroll_target
          ? scrollRight(elem, scroll_left, width)
          : scrollLeft(elem, scroll_left, width)
    }, 80)
  }

  return scroll_interval
}

const handleScroll = (
  e: UIEvent<HTMLDivElement | UIEvent>,
  is_scrolling: NodeJS.Timeout | undefined,
  setActive: (func: (num: number) => number) => void
): NodeJS.Timeout | undefined => {
  if (is_scrolling) {
    window.clearTimeout(is_scrolling)
  }

  const elem: Element | null = document.getElementsByClassName('bgd-display')[0]
  const width: number = (elem as HTMLElement).offsetWidth

  is_scrolling = setTimeout(() => {
    const scroll_value = document.getElementsByClassName('bgd-display')[0].scrollLeft

    setActive((current: number) => {
      return current - Math.round((current * width - scroll_value) / width)
    })

  }, 66)

  return is_scrolling
}

const ButtonGroupDisplay: FC<ButtonGroupDisplayProps> = ({
  className,
  items
}: ButtonGroupDisplayProps) => {
  const [active, setActive] = useState<number>(0)

  let is_scrolling: NodeJS.Timeout | undefined
  let scroll_interval: NodeJS.Timeout | undefined

  useEffect(() => {
    return () => {
      if (is_scrolling) {
        clearTimeout(is_scrolling)
      }

      if (scroll_interval) {
        clearTimeout(scroll_interval)
      }
    }
  }, [])

  return (
    <div id="bgd" className={className}>
      <div aria-label="Display Selector" className="flex w-full bgd-button-group border border-bscs-indigo-800 rounded">
        {items.map((item: Item, index: number) => {
          if (active === index) {
            return (
              <div
                key={`${item.title}-${index}`}
                onClick={(e: MouseEvent) => {
                  scroll_interval = handleClick(e, index, active)
                }}
                className={items.length - 1 === index ? `active p-2 bgd-item-last` : `active p-2 bgd-item-${index}`}
              >
                {item.buttonTitle || item.title}
              </div>
            )
          }

          return (
            <div
              key={`${item.title}-${index}`}
              onClick={(e: MouseEvent) => {
                scroll_interval = handleClick(e, index, active)
              }}
              className={items.length - 1 === index ? `p-2 bgd-item-last` : `p-2 bgd-item-${index}`}
            >
              {item.buttonTitle || item.title}
            </div>
          )
        })}
      </div>
      <div className="flex flex-row bgd-display" onScroll={(e: UIEvent<HTMLDivElement | UIEvent>) => handleScroll(e, is_scrolling, setActive)}>
        {items.map((item: Item, index: number) => {
          return (
            <div key={`bgd-display-${index}`} className="p-2 bgd-display-item">
              <p><strong>{item.title}</strong></p>
              <div dangerouslySetInnerHTML={{__html: item.html}} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ButtonGroupDisplay

